
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'History'
})
export default class History extends Vue {
  tableData = []

  details = {
    latestReportData: {
      auto: ''
    }
  }

  private loading = false
  page = 1
  size = 10
  total = 0

  get id() {
    return this.$route.params.id as string
  }

  created() {
    this.loadData()
  }

  loadData() {
    this.$axios.get(this.$apis.greenhouse.selectControlCabinetById, {
      deviceId: this.id
    }).then(res => {
      this.details = res
      this.tableData = res.configs
    })
  }

  insertControlCabinet(row: any, command: any) {
    console.log('row', row)
    this.$axios.post(this.$apis.greenhouse.insertControlCabinet, {
      deviceId: row.deviceId, // 设备ID
      commandSource: '1', // 指令来源 1:手动 2:定时计划 3:智能计划 4:喷灌互动 5:系统自动
      commandTarget: row.moduleField, // 控制柜设备子模块标识, 参考字典 controlCabinetModule
      commandPos: row.moduleNo, // 控制柜设备子模块位置，排号
      operateCommand: command // 操作指令 0关闭 1开启 2停止
    }).then(() => {
      this.$message.success('操作成功')
      this.loadData()
    })
  }
}
